/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@use '../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
@use '../../node_modules/quill/dist/quill.snow.css';

/* Hot-Toast */
@use '@ngxpert/hot-toast/src/styles/styles.scss';
